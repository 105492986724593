<template>
  <div class="container-fluid text-center error-page" id="error">
    <div class="row content-container d-flex flex-wrap align-content-center">
      <div class="col-10 col-md-6 mx-auto">
        <h1>
          <img :src="require(`@/assets/404.png`)" class="img-fluid" alt="" height="158" width="422">
        </h1>
        <p class="text-center my-4">
          Žao nam je, ova stranica nije pronađena.
        </p>
        <router-link to="/">
          <button class="btn button_red mt-3">
            Nazad na početnu
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>


<script>
export default{
  name: "Error"
}
</script>